<template>
  <v-card
    v-if="cameraDialogStore.camera.exid"
    :loading="cameraDialogStore.loading"
    class="camera-details__content overflow-hidden"
    tile
    :flat="inline"
  >
    <v-card-title class="pa-0 pl-2 camera-details__header">
      <CameraDialogHeader
        v-if="isCameraSelected"
        :camera="cameraDialogStore.camera"
        :inline="inline"
        @close="cameraDialogStore.dialog = false"
      />
    </v-card-title>
    <v-divider />

    <v-container fluid class="pa-0 ma-0 overflow">
      <v-tabs
        v-model="cameraDialogStore.tab"
        :vertical="$vuetify.breakpoint.mdAndUp"
        :show-arrows="$vuetify.breakpoint.smAndDown"
        class="camera-details__tabs custom-tabs"
        active-class="blue lighten-5"
        @change="onTabChange"
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.value"
          class="mx-0 px-6"
          :class="{ 'camera-tab-fullscreen': cameraDialogStore.tabFullScreen }"
        >
          {{ tab.name }}
        </v-tab>

        <v-tab-item v-for="tab in tabs" :key="tab.value">
          <Summary v-if="showTab(tab.value, 'SUMMARY')" :key="camera.exid" />
          <SnapshotExtractions
            v-if="showTab(tab.value, 'EXTRACTIONS')"
            :exid="cameraDialogStore.camera.exid"
          />
          <BrainTool v-if="showTab(tab.value, 'RECOGNITION')" />
          <Demo v-if="showTab(tab.value, 'DEMO')" :camera="camera" />
          <Milestones v-if="showTab(tab.value, 'MILESTONES')" />
          <DangerZone v-if="showTab(tab.value, 'DANGER_ZONE')" />
        </v-tab-item>
      </v-tabs>
    </v-container>
  </v-card>
</template>

<script>
import CameraDialogHeader from "@/components/cameraDialog/CameraDialogHeader"
import { CAMERA_TABS } from "@/components/constants.js"
import Summary from "@/components/cameraTabs/Summary"
import SnapshotExtractions from "@/components/cameraTabs/SnapshotExtractions"
import Demo from "@/components/cameraTabs/Demo"
import BrainTool from "@/components/cameraTabs/BrainTool.vue"
import DangerZone from "@/components/cameraTabs/DangerZone"
import Milestones from "@/components/cameraTabs/Milestones.vue"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useLayoutStore } from "@/stores/layout"

export default {
  name: "CameraDialogContent",
  components: {
    Summary,
    CameraDialogHeader,
    SnapshotExtractions,
    BrainTool,
    Demo,
    DangerZone,
    Milestones,
  },
  props: {
    /**
     * Can be either a camera object, or a String
     * In case of a string (camera exid),
     * the camera details are going to be fetched from server
     */
    camera: {
      type: [String, Object],
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      created: false,
      isCameraSelected: false,
      tabs: [
        { name: "Summary", value: CAMERA_TABS.SUMMARY },
        { name: "Extractions", value: CAMERA_TABS.EXTRACTIONS },
        { name: "BrainTool", value: CAMERA_TABS.RECOGNITION },
        { name: "Demo", value: CAMERA_TABS.DEMO },
        { name: "Milestones", value: CAMERA_TABS.MILESTONES },
        { name: "Danger Zone", value: CAMERA_TABS.DANGER_ZONE },
      ],
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useLayoutStore),
    simNumber() {
      return this.cameraDialogStore.camera?.simNumber
        ? this.cameraDialogStore.camera.simNumber
        : ""
    },
  },
  watch: {
    camera: {
      immediate: true,
      async handler(cam = {}) {
        const exid = typeof cam === "object" ? cam?.exid : cam
        if (exid) {
          this.isCameraSelected = false
          await this.cameraDialogStore.selectCamera(exid)
          this.isCameraSelected = true
        }
      },
    },
    "cameraDialogStore.dialog": {
      immediate: true,
      handler(value, oldValue) {
        if (!value && oldValue) {
          this.reset()

          return
        }

        if (!this.inline && value && (this.camera?.exid || this.camera)) {
          this.clearSubQuery()
          this.updateSubQuery({
            dialog: value,
            exid: this.camera?.exid || this.camera,
          })
        }

        const { tab } = this.getSubQueryParams()
        if (tab !== undefined) {
          this.cameraDialogStore.tab = Number.parseInt(tab)
        }
      },
    },
  },
  mounted() {
    this.$addEventListener("keyup", this.onKeyUp)
  },
  created() {
    this.created = true
    if (this.inline) {
      this.cameraDialogStore.dialog = true
    }
  },
  beforeDestroy() {
    if (!this.inline) {
      this.cameraDialogStore.dialog = false
    }
  },
  methods: {
    toggleFullscreen() {
      this.cameraDialogStore.tabFullScreen =
        !this.cameraDialogStore.tabFullScreen
      this.layoutStore.isMiniSidebar = this.cameraDialogStore.tabFullScreen
    },
    showTab(tabValue, tabName) {
      return tabValue === CAMERA_TABS[tabName] && this.created
    },
    onTabChange(tab) {
      if (!this.cameraDialogStore.dialog) {
        return
      }

      this.updateSubQuery({
        tab: tab,
      })
    },
    reset() {
      this.clearSubQuery()
    },
  },
}
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";
@import "assets/vars";

.camera-details {
  overflow: hidden;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    max-width: calc(100vw - #{$sidebar-width});
    margin-left: $sidebar-width !important;
  }

  &__header {
    box-shadow: 0px 5px 12px -13px #0000007a;
    z-index: 1;
  }

  &__content {
    overflow: hidden;
  }

  &__tabs {
    & > .v-tabs-bar {
      border-right: 1px solid #e0e0e0;
      box-shadow: 5px 0px 11px -12px #000000b5;
      z-index: 1;
    }
    & > .v-tabs-items {
      overflow-y: auto;
      max-height: calc(100vh - 57px);
      height: calc(100vh - 57px);
    }
    .v-tab {
      text-transform: none;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      .v-tabs-bar {
        border-bottom: thin solid #e0e0e0;
      }
    }
  }

  &--fullscreen {
    position: relative;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      max-width: calc(100vw - #{$sidebar-width--sm});
      margin-left: $sidebar-width--sm !important;
    }
  }

  &--fullscreen-no-side {
    position: relative;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      max-width: 100%;
      margin-left: 0 !important;
    }
  }
}

.camera-tab-fullscreen {
  display: none;
}
</style>
